






import Vue from 'vue'

export default Vue.extend({
  name: 'Settings',

  data: function () {
    return {
      data: []
    }
  },

  created () {
    console.log(this.$root.data)
  },

  metaInfo () {
    return {
      title: 'Settings Page',

      meta: [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Settings' },
        { vmid: 'description', name: 'description', content: 'A Vue/TypeScript boilerplate Home Page.' },
        { vmid: 'ogdescription', property: 'og:description', content: 'A Vue/TypeScript boilerplate Home Page.' }
      ]
    }
  }
})
